<template>
  <CRow>
    <CCol col="12">
      <CCard v-if="itemData">
        <CCardHeader> طلب الخدمة رقم: {{ $route.params.id }} </CCardHeader>
        <CCardBody>
          <CAlert color="info">
            <CRow>
              <CCol col="3">حالة طلب الخدمة</CCol>
              <CCol col="3"> {{ itemData.status_name }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3">المحامي</CCol>
              <CCol col="3"> {{ itemData.lawyer_name }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3">موبايل المحامي</CCol>
              <CCol col="3"> {{ itemData.lawyer_phone }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3">العميل</CCol>
              <CCol col="3"> {{ itemData.client_name }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3">موبايل العميل</CCol>
              <CCol col="3"> {{ itemData.client_phone }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3"> السعر الاساسي </CCol>
              <CCol col="3"> {{ itemData.sub_price }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3"> السعر النهائي </CCol>
              <CCol col="3"> {{ itemData.final_price }} </CCol>
            </CRow>
          </CAlert>
        
        </CCardBody>
        <CCardFooter>
          <CButton color="info" @click="goBack">رجوع</CButton>
        </CCardFooter>
      </CCard>
      <Spinner v-else />
    </CCol>
  </CRow>
</template>

<script>
// import serviceRequestsData from "./ServiceRequestsData";
export default {
  name: "ServiceRequest",
  data() {
    return {
      itemData: null,
      serviceRequestsOpened: null,
    };
  },
  created() {
    this.getServiceRequest();
  },
  methods: {
    getServiceRequest() {
      this.toggleLoadSpinner();

      this.$http
        .get("service-requests/" + this.$route.params.id)
        .then((res) => {
          // handle success
          this.itemData = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    goBack() {
      this.serviceRequestsOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/service-requests" });
    },
    getBadge(status) {
      switch (status) {
        case "Active":
          return "success";
        case "Inactive":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },
  },
};
</script>
